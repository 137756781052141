
// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    MemberFlowPage = async () => import(
        /* webpackChunkName: 'member' */
        '../views/MemberFlowPage.vue'
    );

export default [
    // TODO: user-facing URL based on terms in copy doc
    { path: '/member',  component: MemberFlowPage },
];
