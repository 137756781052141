import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withDirectives as _withDirectives, withModifiers as _withModifiers, withKeys as _withKeys, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "the-footer" }
const _hoisted_2 = { class: "footer-links" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["href", "title"]
const _hoisted_5 = ["href", "title"]
const _hoisted_6 = ["href"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["href", "aria-label"]
const _hoisted_9 = {
  key: 0,
  class: "artificial-tests"
}
const _hoisted_10 = ["textContent"]
const _hoisted_11 = ["src"]

import { computed, onMounted } from 'vue';
    import { useI18next } from '@composables/i18next';
    import { useStore } from 'vuex';
    import LanguageSelector from '@components/LanguageSelector.vue';
    import { resolveWebpackI18nAsset } from '../plugins/I18nAssetsPlugin';
    import { openPopup } from '../popup';

    
export default {
  __name: 'TheFooter',
  setup(__props) {

    const store = useStore();

    const { t, vT } = useI18next(['footer', 'links', 'global']);

    const profile = computed(() => store.state.profile);
    const app = computed(() => store.state.app);
    const ui = computed(() => store.state.ui);

    const faqUrl = computed(() => {
        if (store.state.app?.siteURL.includes('localhost')) {
            return `/faq/?locale=${store.state.app.locale}`;
        }

        return '/faq';
    });

    const loggedIn = computed(() => store.getters['profile/loggedIn']);
    const logOut = () => store.dispatch('profile/logOut');

    onMounted(() => {
        // Reinitialize client cookie gate just in case
        window.truste?.eu?.init && window.truste?.eu?.init();
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("img", {
        src: _unref(resolveWebpackI18nAsset)('img/sr-logo.svg'),
        class: "footer-logo mb-m",
        alt: ""
      }, null, 8, _hoisted_3),
      (_unref(store).state.app.userCountry === 'CA')
        ? (_openBlock(), _createBlock(LanguageSelector, { key: 0 }))
        : _createCommentVNode("", true),
      _cache[3] || (_cache[3] = _createElementVNode("div", {
        id: "teconsent",
        class: "mb-xs"
      }, null, -1)),
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: _unref(t)('links:privacy_url'),
            title: _unref(t)('links:privacy_title'),
            target: "_blank"
          }, _toDisplayString(_unref(t)('links:privacy_copy')), 9, _hoisted_4)
        ]),
        _createElementVNode("li", null, [
          _withDirectives(_createElementVNode("a", {
            href: _unref(t)('links:terms_url'),
            title: _unref(t)('links:terms_title'),
            target: "_blank"
          }, null, 8, _hoisted_5), [
            [_unref(vT), 'links:terms_copy']
          ])
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("li", { class: "break" }, null, -1)),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: faqUrl.value,
            target: "_blank",
            onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => (_unref(openPopup) && _unref(openPopup)(...args)), ["prevent"])),
            onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers((...args) => (_unref(openPopup) && _unref(openPopup)(...args)), ["prevent"]), ["enter"]))
          }, _toDisplayString(_unref(t)('links:faq_copy')), 41, _hoisted_6)
        ]),
        (loggedIn.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
              _withDirectives(_createElementVNode("a", {
                href: _unref(store).state.app.sbuxLogoutURL,
                "data-e2e": "footer-logout",
                "aria-label": _unref(t)('links:sign_out_copy'),
                onClick: logOut
              }, null, 8, _hoisted_8), [
                [_unref(vT), 'links:sign_out_copy']
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (app.value.isAutomatedTest && !_ctx.$store.state.ui.rapidTemplate)
      ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
          _createElementVNode("span", {
            class: "test-copy",
            textContent: _toDisplayString(_unref(t)('testNamespace:testKey'))
          }, null, 8, _hoisted_10),
          _createElementVNode("img", {
            class: "language-flag",
            alt: "Language flag",
            src: _ctx.$i18nAsset('img/flag.svg'),
            style: {"display":"inline","width":"2em","padding":"0 0.5em","vertical-align":"middle"}
          }, null, 8, _hoisted_11)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

}