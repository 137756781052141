import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "general-page error-page" }

import { useI18next } from '@composables/i18next';
    import { usePage } from '@composables/page';


    // Setup
    
export default {
  __name: 'ErrorPage',
  setup(__props) {

    usePage('error');
    const { vT } = useI18next('error');

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createElementVNode("h2", null, null, 512), [
      [_unref(vT), 'error:headline_copy.generic']
    ]),
    _withDirectives(_createElementVNode("p", null, null, 512), [
      [_unref(vT), 'error:body_copy.generic']
    ])
  ]))
}
}

}