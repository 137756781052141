
// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    GuestFlowPage = async () => import(
        /* webpackChunkName: 'guest' */
        '../views/GuestFlowPage.vue'
    ),
    OfferPage = async () => import(
        /* webpackChunkName: 'guest' */
        '../views/OfferPage.vue'
    ),
    OfferConfirmationPage = async () => import(
        /* webpackChunkName: 'guest' */
        '../views/OfferConfirmationPage.vue'
    ),
    OfferIneligiblePage = async () => import(
        /* webpackChunkName: 'guest' */
        '../views/OfferIneligiblePage.vue'
    ),
    OfferOutOfStockPage = async () => import(
        /* webpackChunkName: 'guest' */
        '../views/OfferOutOfStockPage.vue'
    );

export default [
    // TODO: user-facing URL based on terms in copy doc
    { path: '/guest',  component: GuestFlowPage, meta: { public: true } },
    { path: '/offer', component: OfferPage },
    { path: '/offer/confirmation', component: OfferConfirmationPage },
    { path: '/offer/ineligible', component: OfferIneligiblePage },
    { path: '/offer/out-of-stock', component: OfferOutOfStockPage },
];
