
import store from '../store';

export default {
    install: (app, options) => {
        app.config.globalProperties.$i18nAsset = resolveWebpackI18nAsset;
        app.provide('$i18nAsset', resolveWebpackI18nAsset);
    },
};

/**
 * Uses Webpack `require()` functionality to resolve the output URL of the
 * given asset, taking into account the search paths based on locale.
 * https://webpack.js.org/guides/dependency-management/#require-with-expression
 *
 * This method functions similarly to TB2's `c()` or the previous `$assets()`,
 * but only adds locale & language search functionality, since Webpack's
 * `require()` already handles resolving compiled and hashed asset URLs.
 *
 * If a given asset does not require localization, there's no need to use
 * this method, and you should continue to use normal Vue and Webpack asset
 * approaches, i.e. prefer the simpler `src="@public/img/header.png"`
 * unless you need the functionality provided by
 * `:src="$i18nAsset('img/header.png')"`.
 *
 * @example
 * // Assume files located at
 * // '@public/i18n-assets/fr/img/header.png' and
 * // '@public/i18n-assets/share/img/header.png'.
 * <img :src="$i18nAsset('img/header.png')">
 * @param {string} origPath - excluding '@public/i18n-assets/' and the search path, e.g.
 * to resolve '@public/i18n-assets/en-US/img/header.png', pass only 'img/header.png'.
 * @returns {string} - URL to the asset in Webpack's output, suitable for use in Vue
 * components bound to `src` and other attributes.
 * @throws {Error} if the given path is unresolvable, like `require()`. (Be careful to
 * provide a `share/` fallback for every localized file or handle the error, as
 * appropriate.)
 */
export function resolveWebpackI18nAsset (origPath) {

    for (const searchPath of getAssetSearchPaths(store.state.app)) {
        // Because Webpack will process basically everything it finds in this path,
        // we use a specific subdirectory to avoid unnecessary work for assets that
        // don't need this functionality.
        // Note: Webpack requires this string to begin with a literal here, even
        // refactoring to use a constant breaks the static analysis.
        // It also can't use webpack aliases.
        try { return require(`../../i18n-assets/${searchPath}${origPath}`); }
        catch (err) { /* ignore */ }
    }

    // Return placeholder data URI for missing image
    return 'data:image/gif;base64,R0lGODlhAQABAIAAAP7//wAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==';
}

function getAssetSearchPaths ({ locale, userLanguage }) {
    return [
        `${locale}/`,       // e.g. '@public/i18n-assets/en-US/...'
        `${userLanguage}/`, // e.g. '@public/i18n-assets/en/...'
        'share/',           // e.g. '@public/i18n-assets/share/...'
    ];
}
