import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { debounce } from 'lodash';
    import { onBeforeUnmount, onMounted, useTemplateRef } from 'vue';

    import { useI18next } from '@composables/i18next';

    
export default {
  __name: 'SiteErrors',
  setup(__props) {

    const { vT } = useI18next('error');


    const rotatePhoneDialog = useTemplateRef('rotatePhoneDialog');


    function checkRotatePhoneDialog () {
        if (window.matchMedia('(max-height: 480px)\
            and (min-aspect-ratio: 5/4)\
            and (min-width: 420px)\
            and (-webkit-min-device-pixel-ratio: 2)\
            and (orientation: landscape)').matches) {
            rotatePhoneDialog.value.showModal();
        }
        else {
            rotatePhoneDialog.value.close();
        }
    }

    const debouncedCheck = debounce(checkRotatePhoneDialog, 100);


    onMounted(() => {
        checkRotatePhoneDialog();

        window.addEventListener('resize', debouncedCheck, { passive: true });
    });

    onBeforeUnmount(() => {
        window.removeEventListener('resize', debouncedCheck);
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("dialog", {
    ref_key: "rotatePhoneDialog",
    ref: rotatePhoneDialog,
    class: "rotate-phone-dialog"
  }, [
    _withDirectives(_createElementVNode("div", null, null, 512), [
      [_unref(vT), 'error:rotate_phone']
    ])
  ], 512))
}
}

}