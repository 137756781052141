import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TheHeader = _resolveComponent("TheHeader")
  const _component_router_view = _resolveComponent("router-view")
  const _component_LoadingScreen = _resolveComponent("LoadingScreen")
  const _component_TheFooter = _resolveComponent("TheFooter")
  const _component_SiteErrors = _resolveComponent("SiteErrors")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($options.shouldShowHeader)
      ? (_openBlock(), _createBlock(_component_TheHeader, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("main", null, [
      (_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.$route.fullPath
      })),
      ($options.showLoadingScreen)
        ? (_openBlock(), _createBlock(_component_LoadingScreen, {
            key: 0,
            onAssetsLoaded: _cache[0] || (_cache[0] = $event => ($data.assetsLoaded = true))
          }))
        : _createCommentVNode("", true)
    ]),
    ($options.shouldShowFooter)
      ? (_openBlock(), _createBlock(_component_TheFooter, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_SiteErrors)
  ], 64))
}