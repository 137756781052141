import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/loading-animation.webp'


const _hoisted_1 = { class: "loading-screen" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["alt"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.$i18nAsset('img/yir-logo.svg'),
      class: "logo",
      alt: $setup.t('title')
    }, null, 8, _hoisted_2),
    _createElementVNode("img", {
      src: _imports_0,
      class: "animation",
      alt: $setup.t('loading')
    }, null, 8, _hoisted_3)
  ]))
}