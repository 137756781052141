import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M23.5 12c0-6.075-4.925-11-11-11s-11 4.925-11 11 4.925 11 11 11 11-4.925 11-11zm-11.559 3.498-.148-.02a.987.987 0 0 1-.193-.061l-.06-.029a1.02 1.02 0 0 1-.154-.099l-.094-.083-2-1.999a1 1 0 0 1 1.32-1.497l.095.083 1.199 1.198 2.805-3.605a1 1 0 0 1 1.65 1.124l-.072.104-3.5 4.5a.997.997 0 0 1-.175.175l-.123.082-.107.053-.118.04-.088.02a1.005 1.005 0 0 1-.237.014z",
      fill: "currentColor"
    }, null, -1)
  ])))
}