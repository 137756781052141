
import store from '../store/index';

// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments

const
    LandingPage = async () => import(
        /* webpackChunkName: 'base' */
        '../views/LandingPage.vue'
    );


export default [
    { path: '/', redirect: (to) => {
        // Used to send server-side errors to the '/error' page.
        if (store.state.app.defaultRoute) {
            return store.state.app.defaultRoute;
        }
        // Default routes for logged-in users.
        else if (
            store.getters['profile/loggedIn'] &&
            !store.getters['profile/isSessionExpired']
        ) {
            if (store.state.memberData.hasData) {
                return '/member';
            }
            else if (store.state.profile.took_quiz) {
                return '/offer';
            }

            return '/guest';
        }

        // Default route for a user we know nothing about.
        return '/landing';
    } },
    { path: '/landing', alias: '/', component: LandingPage, meta: {
        public: true,
        activeInPhase: ':any',
    } },
];
