import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "visually-hidden" }

import { computed, inject, onMounted, ref } from 'vue';
    import { useStore } from 'vuex';

    import { useI18next } from '@composables/i18next';

    
export default {
  __name: 'TheHeader',
  setup(__props) {

    const store = useStore();
    const { t, vT } = useI18next('global');

    // Data
    const h1 = ref(null);

    // Computed
    const isSmall = computed(() => [
        'offer',
        'offer-confirmation',
        'offer-ineligible',
        'offer-out-of-stock',
    ].includes(store.state.ui.pageName));

    // Methods
    const $i18nAsset = inject('$i18nAsset');

    // Focus the h1 element to force screen readers back to the top after a navigation.
    // This will ensure screen readers will read the page again.
    // https://a11y-guidelines.orange.com/en/articles/single-page-app/#notify-user-of-page-changes
    onMounted(() => h1.value.focus());

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["the-header", { small: isSmall.value }]),
    "data-e2e": "global-header"
  }, [
    _createElementVNode("h1", {
      ref_key: "h1",
      ref: h1,
      tabindex: "-1"
    }, [
      _createElementVNode("img", {
        src: _unref($i18nAsset)(`img/yir-logo${isSmall.value ? '-small' : ''}.svg`),
        alt: _unref(t)('title'),
        "aria-hidden": "true"
      }, null, 8, _hoisted_1),
      _withDirectives(_createElementVNode("span", _hoisted_2, null, 512), [
        [_unref(vT), 'global:title']
      ])
    ], 512)
  ], 2))
}
}

}