
import { onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, toValue } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { page } from '../plugins/WebAnalytics';

export function usePage (pageName, pageClasses = []) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    onBeforeMount(() => {
        store.commit('ui/pageEnter', {
            pageName: toValue(pageName),
            pageClasses: toValue(pageClasses),
        });

        const { query: { affiliate_id } } = route;

        // If there was an affiliate in the "query string" embedded in the Vue
        // Router hash portion of the URL, pass it along to the app store.
        if (affiliate_id) {
            store.commit('app/hashAffiliateUpdate', affiliate_id);
        }

        page();
    });

    onMounted(() => {
        document.body.setAttribute('data-e2e-page', toValue(pageName));
    });

    onBeforeUnmount(() => {
        store.commit('ui/pageLeave');
    });

    onUnmounted(() => {
        document.body.removeAttribute('data-e2e-page');
    });

    return {
        pageName,
        pageClasses,
        router,
        store,
    };
}
